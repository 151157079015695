import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import EventsDashboardTable from "../components/EventsDashboardTable";
import { Tooltip } from "react-tooltip";
import Loading from "./Loading";
import EventsDraftTable from "../components/EventsDraftTable";

import { MdAutoFixHigh } from "react-icons/md";
import { fixDrafts } from "../utils/api";
import { FaSignOutAlt } from "react-icons/fa";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
function EventsDashboard() {
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [draftsSelected, setDraftsSelected] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies(['authJwt']);

  const handleLogout = () => {
    removeCookie('authJwt', { path: '/' });
    navigate('/login');
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="eventsDashboard flex flex-col items-center"
    >
      <div className="fixed top-0 right-0 m-4 z-50">
        <button 
          onClick={handleLogout}
          className="flex items-center gap-2 bg-gradient-to-r from-red-500 to-red-700 text-white px-4 py-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 hover:scale-105"
          data-tooltip-id="logout-button"
          data-tooltip-content="Deslogar da conta"
          data-tooltip-place="left"
        >
          <span className="font-medium">Sair</span>
          <FaSignOutAlt />
        </button>
        <Tooltip id="logout-button" />
      </div>
      <div className="my-4 flex text-2xl tracking-wide font-bold bg-[#D9D9D9] rounded-full select-none">
        <div 
          className={`${draftsSelected ? 'hover:bg-[#F8F8F8]/50 text-[#AAAAAA] cursor-pointer' : 'bg-[#F8F8F8] shadow-lg'} rounded-full py-3 px-12 transition-all duration-300`}
          onClick={() => setDraftsSelected(false)}
        >
          <h2 className="text-center">Lista de Eventos</h2>
        </div>
        <div 
          className={`${draftsSelected ? 'bg-[#F8F8F8] shadow-lg' : 'text-[#AAAAAA] hover:bg-[#F8F8F8]/50 cursor-pointer'} rounded-full py-3 px-12 transition-all duration-300`}
          onClick={() => setDraftsSelected(true)}
        >
          <h2 className="text-center">Rascunhos</h2>
        </div>
      </div>
      <AnimatePresence>
        {draftsSelected ? (
          <EventsDraftTable onLoad={() => setLoading(false)} />
        ) : (
          <EventsDashboardTable onLoad={() => setLoading(false)} />
        )}
      </AnimatePresence>
      {loading && <Loading />}
      <div className="fixed w-full h-full flex justify-end items-end pointer-events-none">
        <a 
          className="flex justify-center items-center bg-blue-500 w-12 h-12 mx-12 my-4 text-4xl font-bold rounded-full text-white transition-transform duration-300 hover:scale-110 pointer-events-auto"
          href="/events/create"
          data-tooltip-id="new-event-button"
          data-tooltip-content="Criar Evento"
          data-tooltip-place="top-start"
        >
          +
        </a>
        <Tooltip id="new-event-button" />
      </div>
      {/* <AnimatePresence>
        {draftsSelected && (
          <div className="fixed w-full h-full flex justify-end items-start pointer-events-none">
            <motion.button 
              initial={{scale: 0}}
              animate={{scale: 1}}
              exit={{scale: 0}} 
              transition={{ease: "easeOut", duration: 0.5}}
              className="flex justify-center items-center bg-blue-500 w-12 h-12 mx-12 my-4 text-4xl font-bold rounded-full text-white transition-transform duration-300 hover:scale-110 pointer-events-auto"
              onClick={() => fixDrafts()}
              data-tooltip-id="fix-draft-button"
              data-tooltip-content="Arrumar Problemas"
              data-tooltip-place="bottom-start"
            >
              <MdAutoFixHigh size={24} />
            </motion.button>
            <Tooltip id="fix-draft-button" />
          </div>
        )}
      </AnimatePresence> */}
    </motion.div>
  );
}

export default EventsDashboard;